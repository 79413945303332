#headerMain {
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	width: 35vh;
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem 1rem;
	font-family: "NoirProLight";

	#logo {
		background-image: url("../../assets/logowhite.png");
		background-position: center left;
		background-size: contain;
		background-repeat: no-repeat;
		border: 0;
		height: 3rem;
		border-radius: 0;
	}
	#fontsList {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		a {
			width: fit-content;
		}
	}
	h3 {
		color: white;
		display: flex;
		align-items: center;
		margin-bottom: 0;
	}
	a {
		text-decoration: none;
		color: white;
		border: 1px solid white;
		padding: 0.25rem;
		border-radius: 5px;
		text-transform: uppercase;
	}

	.btns {
		display: flex;
		gap: 1rem;
		margin-left: 2rem;
		margin-top: 0;
		.lightBtn {
			aspect-ratio: 1/1;
			width: 0.5rem;
			border-radius: 50%;
			background-color: white;
			border: 1px solid darkorange;
		}
		.darkBtn {
			aspect-ratio: 1/1;
			width: 0.5rem;
			border-radius: 50%;
			background-color: black;
			border: 1px solid darkorange;
		}
	}
}
#headerMainMob {
	display: none;
}

@media screen and (max-width: 425px) {
	#headerMain {
		display: none;
	}
	#headerMainMob {
		position: fixed;
		top: 0;
		right: 0;
		padding-right: 1rem;
		padding-top: 2rem;
		z-index: 1;
		width: 5rem;
		display: flex;
		justify-content: center;

		#mainBtn {
			background-color: black;
			border: 1px solid white;
			border-radius: 50%;
			aspect-ratio: 1/1;
			width: 4rem;
			background-image: url("../../assets/logowhiteNoText.png");
			background-position: center center;
			background-size: 2rem;
			background-repeat: no-repeat;
		}

		.OffCanvasBox {
			position: absolute;
			top: 0;
			right: 0;
			background-color: black;
			width: 50%;
			height: 100vh;
		}
	}
}
