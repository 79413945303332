#homeMain {
	margin-left: calc(31.55vh + 2rem);
	padding: 0;
	background-color: black;
	display: flex;
	flex-direction: column;
	gap: 2rem;

	.canvasContainer {
		height: 96.6vh;
		// color: #32056e;
	}
	#sec1 {
		display: flex;
		justify-content: space-between;

		p {
			color: white;
			width: 30%;
		}
		span {
			color: white;

			a {
				color: white;
			}
		}
	}
	#check {
		color: white;
		text-align: center;
	}
}

@media screen and (max-width: 425px) {
	#homeMain {
		margin-left: 0rem;

		#sec1 {
			flex-direction: column;
			p {
				width: 100%;
			}
		}
	}
}
