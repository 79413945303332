@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

#CairoMain {
	margin-left: calc(35vh + 2rem);
	padding: 0rem;
	background-color: black;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	color: white;

	.canvasContainer {
		height: 96.6vh;
		// color: #32056e;
		#DblTp {
			display: none;
		}
	}
}

@media screen and (max-width: 425px) {
	#CairoMain {
		margin-left: 0;
		.canvasContainer {
			height: 96.6vh;
			// color: #32056e;
			#DblTp {
				display: inline;
				text-align: center;
				width: 100vw;
				color: black;
				font-family: "Cairo", sans-serif;
				font-weight: 800;
				font-size: larger;
				// mix-blend-mode: difference;
				position: absolute;
				top: 0;
				left: 0;
				text-transform: capitalize;
				z-index: 10;
			}
		}
	}
}
