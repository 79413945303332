#BerMain {
	margin-left: calc(35vh + 2rem);
	padding: 1rem 0rem;
	background-color: black;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	color: white;
	.canvasContainer {
		height: 96.6vh;
		// color: #32056e;
	}
}

@media screen and (max-width: 425px) {
	#BerMain {
		margin-left: 0;
	}
}
