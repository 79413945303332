@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");

@font-face {
	font-family: NoirProLight;
	src: url("./NoirPro/NoirPro-Light.woff2");
}
@font-face {
	font-family: NoirProHeavy;
	src: url("./NoirPro/NoirPro-Heavy.woff2");
}
@font-face {
	font-family: NoirProBold;
	src: url("./NoirPro/NoirPro-Bold.woff2");
}
@font-face {
	font-family: NoirProSemiBold;
	src: url("./NoirPro/NoirPro-SemiBold.woff2");
}
@font-face {
	font-family: NoirProMedium;
	src: url("./NoirPro/NoirPro-Medium.woff2");
}
@font-face {
	font-family: NoirPro;
	src: url("./NoirPro/NoirPro-Regular.woff2");
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgb(55, 55, 55);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(255, 94, 0);
}
