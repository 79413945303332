.CardBody {
	border: 1px solid grey;
	border-radius: 15px;
	font-family: none;
	.CardHeader {
		border-bottom: 1px solid grey;
		border-radius: 15px 15px 0px 0px;
		padding: 0.5rem;
		background-color: #121212;
		display: flex;
		justify-content: space-between;
		align-items: center;

		select {
			background-color: transparent;
			color: white;
			border: 0;
		}

		button {
			border: 0;
			background-color: transparent;
			color: white;
			font-weight: 500;
		}
		.exp {
			border: 1px solid white;
			border-radius: 5px;
			padding: 0.25rem;
			&:hover {
				border-color: orangered;
			}
			a {
				color: white;
				text-decoration: none;
				&:hover {
					color: orangered;
				}
			}
		}
	}

	.CardText {
		outline: 0px solid transparent;
		background-image: radial-gradient(circle at 0px 0px, rgba(128, 128, 128, 0.5) 1px, transparent 0);
		background-size: 10px 10px;
		// background-size: cover;
		background-repeat: repeat-y repeat-x;
		padding: 100px 0px;

		#txt {
			color: white;
			font-size: 100px;
			text-align: center;
			margin: 0;
			padding: 0rem 1rem;
		}
	}
}

@media screen and (max-width: 425px) {
	.CardBody {
		.CardText {
			padding: 20px 5px;
			#txt {
				font-size: 30px;
				// color: red;
			}
		}
	}
}
