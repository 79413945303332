.linkList {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.linkList a {
	text-decoration: none;
	color: white;
	border: 1px solid white;
	padding: 0.25rem;
	border-radius: 5px;
	text-transform: uppercase;
	width: fit-content;
}
.header3 {
	display: flex;
	align-items: center;
	color: white;
}
#toHome {
	text-decoration: none;
	color: white;
	border: 1px solid white;
	padding: 0.25rem;
	border-radius: 5px;
	text-transform: uppercase;
}

.btns {
	display: flex;
	gap: 1rem;
	margin-left: 2rem;
	margin-top: 0;
}

.btns .lightBtn {
	aspect-ratio: 1/1;
	width: 0.5rem;
	border-radius: 50%;
	background-color: white;
	border: 1px solid darkorange;
}
.btns .darkBtn {
	aspect-ratio: 1/1;
	width: 0.5rem;
	border-radius: 50%;
	background-color: black;
	border: 1px solid darkorange;
}
