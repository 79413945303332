#ComMain {
	margin-left: calc(35vh + 2rem);
	padding: 0rem 0rem;
	background-color: black;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	color: white;

	.canvasContainer {
		height: 96.6vh;
		// color: #32056e;
		padding: 0rem 0rem;
	}
}
@media screen and (max-width: 425px) {
	#ComMain {
		margin-left: 0;
		padding: 0.5rem;
	}
}
